import HTTP from '../http';

const API_URL = process.env.VUE_APP_AUTH_URL;
const API_URL_RESET = process.env.VUE_APP_AUTH_URL_RESET;

class AuthService {
    login(user) {
        return HTTP
            .post(API_URL, new URLSearchParams({
                grant_type: 'password',
                client_id: 'immolert-app',
                username: user.username,
                password: user.password
            }), {
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded"
                }
            })
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('refreshToken', response.data.refresh_token);
                }
                return response.data.access_token;
            });
    }

    refresh() {
        return HTTP
            .post(API_URL, new URLSearchParams({
                grant_type: 'refresh_token',
                client_id: 'immolert-app',
                refresh_token: localStorage.getItem("refreshToken")
            }), {
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded"
                }
            });
    }

    changePassword(oldpassword, newpassword, confirmation) {
        return HTTP
            .post(API_URL_RESET, {
                currentPassword: oldpassword,
                newPassword: newpassword,
                confirmation: confirmation
            }, {
                headers: {
                    'Content-Type': "application/json"
                }
            });
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
    }

    /*
    register(user) {
      return axios.post(API_URL + 'signup', {
        username: user.username,
        email: user.email,
        password: user.password
      });
    }*/
}

export default new AuthService();
