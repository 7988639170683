import Vue from "vue";

class MessageService {
    context;

    checkContext() {
        if (this.context === undefined || this.context === null || this.context.$bvToast === undefined || this.context.$bvToast === null) {
            this.context = new Vue();
        }
    }

    success(title, message) {
        this.checkContext();
        this.context.$bvToast.toast(message, {
            title: title,
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: false
        });
    }

    error(title, message) {
        this.checkContext();
        this.context.$bvToast.toast(message, {
            title: title,
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false
        });
    }

    hint(title, message) {
        this.checkContext();
        this.context.$bvToast.toast(message, {
            title: title,
            autoHideDelay: 5000,
            variant: 'warning',
            appendToast: false
        });
    }
}

export default new MessageService();